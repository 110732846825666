<template>
  <div>
    <!-- Permite master escolher a empresa -->
    <label v-if="idDoPerfil === 1">Cliente:</label>
    <label v-else>Empresa:</label>
    <!-- Mostro componente para selecionar
    se for master e campo for obrigatório -->
    <validation-provider
      v-if="idDoPerfil === 1 && campoObrigatorio"
      #default="{ errors }"
      name="Cliente"
      rules="required"
    >
      <vue-autosuggest
        v-model="nomeDoCliente"
        :suggestions="filteredOptions"
        :limit="limit"
        :input-props="inputProps"
        :get-suggestion-value="getSuggestionValue"
        @selected="onSelected"
        @input="onInputChange"
        @blur="onBlur"
        @click="onInputChange(' ')"
      >
        <template slot-scope="{ suggestion }">
          <span class="my-suggestion-item">{{
            suggestion.item.nomeDoCliente
          }}</span>
        </template>
      </vue-autosuggest>
      {{
        errors[0]
          ? alteraClasseValidarCliente(false)
          : alteraClasseValidarCliente(true)
      }}
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

    <!-- Mostro componente para selecionar
    se for master e campo NÃO for obrigatório -->
    <vue-autosuggest
      v-else-if="idDoPerfil === 1 && !campoObrigatorio"
      v-model="nomeDoCliente"
      :suggestions="filteredOptions"
      :limit="limit"
      :input-props="inputProps"
      :get-suggestion-value="getSuggestionValue"
      @selected="onSelected"
      @input="onInputChange"
      @blur="onBlur"
      @click="onInputChange(' ')"
    >
      <template slot-scope="{ suggestion }">
        <span class="my-suggestion-item">{{
          suggestion.item.nomeDoCliente
        }}</span>
      </template>
    </vue-autosuggest>

    <!-- Se não for master já trago preenchido -->
    <b-form-input
      v-else
      :placeholder="clienteLogado.nomeDoCliente"
      disabled
    />
  </div>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt"
import { VueAutosuggest } from "vue-autosuggest"
import { BFormInput } from "bootstrap-vue"
import { ValidationProvider } from "vee-validate"
export default {
  components: {
    VueAutosuggest,
    ValidationProvider,
    BFormInput
  },
  props: {
    titulo: {
      type: String,
      default: "Cliente"
    },
    pesquisa: {
      // Caso essa propriedade seja recebida do pai
      // Vai disparar o emit iniciarPesquisa para o pai
      type: Boolean,
      required: false,
      default: false
    },
    clientePreenchido: {
      // Caso essa propriedade seja recebida do componente pai
      // Vai iniciar já com o cliente preenchido
      type: Number,
      required: false,
      default: 0
    },
    desabilitarCampo: {
      type: Boolean,
      required: false,
      default: false
    },
    campoObrigatorio: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      nomeDoCliente: null,
      inputProps: {
        id: "cliente_autosuggest__input",
        class: "form-control",
        placeholder: "Pesquise e Selecione o " + this.titulo,
        disabled: this.desabilitar()
      },
      idDoPerfil: this.$store.state.user.idDoPerfil,
      clienteLogado: {
        idDoCliente: 0,
        nomeDoCliente: ""
      },
      cliente: {
        idDoCliente: 0,
        nomeDoCliente: ""
      },
      clienteZerado: {
        idDoCliente: 0,
        nomeDoCliente: ""
      },
      datasuggest: [],
      filteredOptions: [],
      limit: 20,
      selected: null,
      listaDeClientes: []
    }
  },
  mounted() {
    // Busca lista completa no back -->
    this.getListaDeClientes()
    this.desabilitar()
  },
  methods: {
    desabilitar() {
      return this.desabilitarCampo
    },
    alteraClasseValidarCliente(valido) {
      if (valido) {
        this.inputProps.class = "form-control"
        this.inputProps.style = ""
      } else {
        this.inputProps.class = "form-control is-invalid"
        this.inputProps.style = "margin-bottom: 1rem;"
      }
    },
    validarCliente(cliente) {
      if (this.campoObrigatorio) {
        this.nomeDoCliente = cliente.nomeDoCliente
      }
    },
    onSelected(item) {
      // Esse é o resultado que foi selecionado na listaDeClientes
      this.selected = item.item
      this.$emit("clienteSelecionado", this.selected)
      this.validarCliente(this.selected)
      // console.log("Selecionado: ", this.selected)
    },
    getSuggestionValue(suggestion) {
      // console.log("Chamou suggestion> ", suggestion.item.nomeDoCliente)
      // Esse é o resultado retornado para o input quando
      // o master clica em uma opção da listaDeClientes
      return suggestion.item.nomeDoCliente
    },
    onBlur(text) {
      setTimeout(() => {
        if (text === "" || text === undefined || text === null) {
          this.$emit("clienteSelecionado", this.clienteZerado)
        }
      }, 1000)
    },
    onInputChange(text) {
      // Essa função faz o filtro ao iniciar a pesquisa
      if (text === "" || text === undefined || text === null) {
        // console.log("vazio, undefine ou null")
        this.$emit("clienteSelecionado", this.clienteZerado)
        this.validarCliente(this.clienteZerado)
        return
      }
      // console.log("text: ", text)
      // console.log("this.listaDeClientes: ", this.listaDeClientes)

      try {
        const clientesFiltrados = this.listaDeClientes
          .filter(
            item =>
              item.nomeDoCliente
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .indexOf(
                  text
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                ) > -1
          )
          .slice(0, this.limit)

        this.filteredOptions = [
          {
            data: clientesFiltrados
          }
        ]
      } catch (error) {
        console.error("erro: ", error)
      }
    },
    async getListaDeClientes() {
      // Preenche a listaDeClientes com id e nome do cliente
      const resp = await useJwt
        .getListaDeClientes()
        .then(response => response.data)
        .catch(response => {
          response.data
        })

      if (resp.sucesso) {
        // Cria a lista de clientes em this.listaDeClientes
        Object.entries(resp.dados.usuarios).forEach(e =>
          this.listaDeClientes.push({
            idDoCliente: e[1].idDoCliente,
            nomeDoCliente: e[1].nomeDoCliente
          })
        )

        // Identifica o id do cliente do usuário logado
        const idDoClienteLogado = JSON.parse(localStorage.userData).idDoCliente
        // console.log("idDoClienteLogado: ", idDoClienteLogado)

        // Pega id e nome do cliente do usuário logado
        this.clienteLogado = this.listaDeClientes.find(
          x => x.idDoCliente === idDoClienteLogado
        )

        // console.log("clienteLogado: ", this.clienteLogado)
        // console.log("Perfil logado: ", this.idDoPerfil)

        if (this.idDoPerfil !== 1) {
          // Se o perfil não for master, passa this.clienteLogado para o componente pai
          // console.log("perfil diferente de 1")
          this.$emit("clienteSelecionado", this.clienteLogado)
          this.validarCliente(this.clienteLogado)
        } else {
          // Se o perfil for master, verifica se recebeu ordem para preencher cliente
          if (this.clientePreenchido !== 0) {
            console.log("Cliente preenchido", this.clientePreenchido)
            // Caso sim passa cliente preenchido
            const cliente = this.listaDeClientes.find(
              x => x.idDoCliente === this.clientePreenchido
            )
            this.$emit("clienteSelecionado", cliente)
            this.validarCliente(cliente)

            // E adiciona no valor inicial
            this.nomeDoCliente = cliente.nomeDoCliente
            return
          }
          // Caso contrário o componente pai deve pesquisar por tudo se for 0
          this.$emit("clienteSelecionado", this.clienteZerado)
          this.validarCliente(this.clienteZerado)
        }
        // Pede para o componente pai iniciar a pesquisa
        if (this.pesquisa) {
          // console.log("Iniciar pesquisa...")
          this.$emit("iniciarPesquisa")
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Erro ao listar clientes, Por favor, verifique sua conexão com a internet e tente novamente!",
            icon: "ErrorIcon",
            variant: "danger"
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
