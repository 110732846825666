<template>
  <div>
    <transition name="fade">
      <b-card v-if="idDoCliente > 0 || mode == 'I'">
        <b-col md="12">
          <div>
            <h1>Cadastro de Cargos</h1>
          </div>
        </b-col>
        <b-col
          md="12"
          class="mt-2"
        >
          <selecionar-cliente
            v-if="idDoPerfil === 1 && mode != 'V'"
            :campo-obrigatorio="true"
            :cliente-preenchido="idDoCliente"
            @clienteSelecionado="selecionaCliente"
          />
          <b-form-input
            v-else-if="idDoPerfil === 1"
            v-model="nomeDoCliente"
            disabled
          />
          <br>
          <b-form-group label="Cargo">
            <validation-observer ref="basicValidation">
              <validation-provider
                v-slot="{ errors }"
                name="Nome do Cargo"
                rules="required"
              >
                <b-form-input
                  v-model="form.role"
                  :state="errors.length > 0 ? false : null"
                  :disabled="mode === 'V'"
                />
                <span>{{ errors[0] }}</span>
              </validation-provider>
            </validation-observer>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <div class="demo-inline-spacing">
            <b-button
              v-if="mode != 'V'"
              variant="primary"
              @click="handleConfirm()"
            >
              Confirmar
            </b-button>
            <b-button
              v-if="mode === 'V'"
              variant="primary"
              @click="handleConfirm()"
            >
              Retornar
            </b-button>
          </div>
        </b-col>
      </b-card>
    </transition>
  </div>
</template>

<script>
export default {
  components: {
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormInput,
    BCol,
    BCard,
    SelecionarCliente,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  data() {
    return {
      mode: "",
      required,
      isValid: false,
      idDoCliente: 0,
      getidDoCliente: "",
      nomeDoCliente: "",
      idDoPerfil: 0,
      roleId: 0,
      form: {
        role: ""
      },
      errors: []
    }
  },

  mounted() {
    this.idDoPerfil = Number(getUserData().idDoPerfil)
    this.roleId = parseInt(this.$route.params.id)
    if (this.$route.path === "/cargos/cadastro") {
      this.mode = "I"
      this.getidDoCliente = this.idDoCliente
    } else if (this.$route.path.includes("detalhes-cargo")) {
      this.mode = "V"
      this.getRole(this.roleId)
    } else if (this.$route.path.includes("editar-cargo")) {
      this.getRole(this.roleId)
      this.mode = "A"
    }
  },
  methods: {
    selecionaCliente(cliente) {
      // console.log(cliente)
      // console.log("IdDoCliente: ", this.idDoCliente)
      const obj = JSON.parse(JSON.stringify(cliente))
      this.getidDoCliente = obj.idDoCliente
    },

    async handleConfirm() {
      switch (this.mode) {
        case "I": {
          this.registerRole()
          break
        }
        case "V": {
          this.$router.push({ name: "role-view" })
          break
        }
        case "A": {
          const usuarioLocalStorage = getUserData()
          const isValid = await this.$refs.basicValidation.validate()
          if (isValid && this.idDoPerfil != 1) {
            this.updateRole(usuarioLocalStorage.idDoCliente)
            this.$router.push({ name: "role-view" })
          } else if (isValid && this.idDoPerfil === 1) {
            this.updateRole(this.getidDoCliente)
            this.$router.push({ name: "role-view" })
          }
          break
        }
      }
    },
    async registerRole() {
      const usuarioLocalStorage = getUserData()
      if (this.idDoPerfil === 1) {
        this.idDoCliente = this.getidDoCliente
      } else {
        this.idDoCliente = usuarioLocalStorage.idDoCliente
      }
      const isValid = await this.$refs.basicValidation.validate()
      if (isValid) {
        const response = await useJwt
          .cadastroCargo({
            idDoCliente: this.idDoCliente,
            descricaoDaFuncao: this.form.role
          })
          .then(response => response.data)
          .catch(response => response.data)
        if (response.sucesso) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            timeout: 5000,
            props: {
              title: "Cargo incluído com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
              text: "Você já pode realizar a inclusão de um novo cargo"
            }
          })
          this.form.role = ""
          this.$refs.basicValidation.reset()
        }
      }
    },
    async updateRole(id) {
      const response = await useJwt
        .alterarCargo(
          {
            idDoCliente: id,
            descricaoDaFuncao: this.form.role
          },
          this.roleId
        )
        .then(response => response.data)
        .catch(response => response.data)
      if (response.sucesso) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          timeout: 5000,
          props: {
            title: "Cargo alterado com sucesso!",
            icon: "CoffeeIcon",
            variant: "success",
            text: "Você já pode realizar a inclusão/alteração de um novo cargo"
          }
        })
      }
    },
    async getRole(id) {
      const response = await useJwt
        .consultaCargo(id)
        .then(response => response.data)
        .catch(response => response.data)
      if (response.sucesso) {
        this.form.role = response.dados.consultaFuncao.descricaoDaFuncao
        this.idDoCliente = response.dados.consultaFuncao.idDoCliente
        this.nomeDoCliente = response.dados.consultaFuncao.nomeDoCliente
      }
    }
  }
}
import useJwt from "@/auth/jwt/useJwt"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { required } from "@validations"
import { BButton, BFormInput, BCol, BCard, BFormGroup } from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { getUserData } from "@/auth/utils"
import SelecionarCliente from "@/views/global/SelecionarCliente.vue"
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
